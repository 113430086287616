@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "IBM Plex Sans", sans-serif;
}

#messages-render {
  scroll-behavior: smooth;

}
